import React, { useEffect } from 'react';
import Layout from '../components/layout';
import { navigate } from 'gatsby-link';
import { useTranslation } from 'react-i18next';

export default () => {
  const browser = typeof window !== 'undefined';
  const {t, i18n} = useTranslation();
  
  useEffect(() => {
    setTimeout(() => {
      navigate(i18n.language === 'ar' ? '/ar/' : '/');
    }, 5000);
  }, []);

  return browser && <Layout>Not found</Layout>;
};
